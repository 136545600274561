import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  listEmployees,
  uploadImmigrationFile,
  exportEmployees as exportEmployeesApi,
  sendEmployeesDiaries,
} from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";
import { withNotifications } from "../notifications/notificationsSlice";

export const fetchPage = createAsyncThunk(
  "employees/loadPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await listEmployees(listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load employees", message: error })
      );
    }
    return data;
  }
);

export const exportEmployees = createAsyncThunk(
  "employees/export",
  withNotifications(
    exportEmployeesApi,
    "export_employees",
    "export_employees_success",
    "export_employees_error"
  )
);

const onItemChanged = (state, action) => {
  const changedItem = action.payload.data;
  const { items } = state.page;
  state.page.items = items.map((i) => {
    if (i.id === changedItem.id) {
      return changedItem;
    }
    return i;
  });
};

export const uploadForeignFile = createAsyncThunk(
  "employees/uploadImmigrationFile",
  withNotifications(
    uploadImmigrationFile,
    "upload_immigration_file",
    "upload_immigration_file_success",
    "upload_immigration_file_error"
  )
);

export const sendDiaries = createAsyncThunk(
  "employees/sendDiaries",
  withNotifications(
    sendEmployeesDiaries,
    "send_employees_diaries",
    "send_employees_diaries_success",
    "send_employees_diaries_error"
  )
);

const initialPageSize = 10;
const initialState = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
      $and: [{ care_giver: true }, { $or: [{ status: 1 }, { status: 3 }] }],
    },
    order: {
      field: "updated_at",
      order: "descend",
    },
    items: [],
    finalfilters: {
      status: [],
      gender_id: [],
      employee_type_id: [],
      branches: [],
    },
    fields:
      "first_name,surename,local_id_number,foreign,contact_info,birthday,gender_id,employee_type_id,address,avatar_url,id,branch_active,active_branches,active,work_periods",
  },
  templates: {
    items: [],
  },
};

const employeesSlice = createSlice({
  name: "employees",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setTextFilter(state, action) {
      const { text } = action.payload;
      state.page = {
        ...state.page,
        filter: {
          ...state.page.filter,
          freeText: text,
        },
      };
    },
    reset(state, action) {
      state.page = initialState.page;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },

    "employeeDetails/activate/fulfilled": onItemChanged,
    "employeeDetails/deactivate/fulfilled": onItemChanged,
    "employeeDetails/update/fulfilled": onItemChanged,
    "employeeDetails/delete/fulfilled": (state, action) => {
      const { items } = state.page;
      state.page.items = items.filter((item) => item.id !== action.meta.arg);
    },
  },
});

export const { setPage, reset, setTextFilter } = employeesSlice.actions;

export default employeesSlice.reducer;

export const loadPage = (params, query) => (dispatch, getState) => {
  const { finalfilters, order } = params;
  let newOrder = order;
  if (!order.order) {
    newOrder = initialState.page.order;
  }

  let filter = {
    freeText: params.filter.freeText,
  };

  filter["$and"] =
    params && params.filter["$and"]
      ? [
          ...params.filter["$and"].filter((i) => i.care_giver !== false),
          { care_giver: true },
        ]
      : [{ care_giver: true }];
  let newParams = { ...params, order: newOrder, filter };

  if (finalfilters && !finalfilters.status) {
    const otherFilters = params.filter["$and"] ? params.filter["$and"] : [];
    newParams.filter = {
      $and: [{ $and: initialState.page.filter["$and"] }, ...otherFilters],
      freeText: params.filter.freeText,
    };
  }
  const newState = {
    ...getState().employees.page,
    ...newParams,
  };
  const scopePrefix = getState().user.scopePrefix;
  const newQuery = query ? query : { branches: newState.finalfilters.branches };

  dispatch(setPage(newState));
  dispatch(fetchPage({ ...newState, scopePrefix, query: newQuery }));
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().employees.page));
};

export const generateLink = (id, state) => {
  let scopePrefix = state.user.scopePrefix;
  const activePatient = state.patientDetails.activePatient;
  if (scopePrefix === "") {
    scopePrefix = `branches/${activePatient.branch}/`;
  }
  return `/${scopePrefix}employees/${id}`;
};
