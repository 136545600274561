import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import axios from "axios";
import { message } from "antd";
import {
  multiFactor,
  TotpMultiFactorGenerator,
  getMultiFactorResolver,
} from "firebase/auth";
import {
  setUser,
  fetchUserProfile,
  setSigningOutProcess,
  setEnrolledTotpModalVidible,
} from "../features/user/UserSlice";
import { loadRecord } from "../features/organization/organizationSlice";
import { getCurrentTenant } from "../api/server";

import store from "../redux/store";

export const configurations = {
  production: {
    apiKey: "AIzaSyDs2QkqANgCTQIcThQFCiNkxcKGPVkS_lM",
    authDomain: "steam-capsule-316104.firebaseapp.com",
    projectId: "steam-capsule-316104",
    storageBucket: "steam-capsule-316104.appspot.com",
    messagingSenderId: "793066330760",
    appId: "1:793066330760:web:c38f3c358a13eb50de1d3d",
    masterTenant: "master-ujuwd",
    masterDomain: "master.a-dreams.com",
  },
  matav: {
    apiKey: "AIzaSyC4XYWtPgsMSIHkzJrRm4lz4Osae97BY2Y",
    authDomain: "coherent-medium-332412.firebaseapp.com",
    projectId: "coherent-medium-332412",
    storageBucket: "coherent-medium-332412.appspot.com",
    messagingSenderId: "65563686053",
    appId: "1:65563686053:web:118095370539a6dbd5494c",
    masterTenant: "master-zeevx",
    masterDomain: "dreams-master.matav.org.il",
  },
  dev: {
    apiKey: "AIzaSyB9qwava8-KbE--nFcoMVDcsgvJAqgmxLc",
    authDomain: "dreams-da917.firebaseapp.com",
    databaseURL: "https://dreams-da917.firebaseio.com",
    projectId: "dreams-da917",
    storageBucket: "dreams-da917.appspot.com",
    messagingSenderId: "320497435948",
    appId: "1:320497435948:web:229b0bae39762d95bdd889",
    masterTenant: "test-master-mw5sz",
    masterDomain: "localhost:3000",
  },
};

export const firebaseConfig =
  configurations[process.env.REACT_APP_CONFIG || "dev"];

export const firebaseApp = firebase.initializeApp(firebaseConfig);
firebaseApp.auth().languageCode = "he";
// Constants
const TWO_HOURS_MS = 2 * 60 * 60 * 1000;

const setExpiration = (autoLoginDays) => {
  const expirationTime =
    autoLoginDays > 0
      ? Date.now() + autoLoginDays * 24 * 60 * 60 * 1000
      : Date.now() + TWO_HOURS_MS;
  localStorage.setItem("dreams-expiration", expirationTime);
};

axios.interceptors.request.use((request) => {
  const auto_login_days =
    store.getState().organization?.details?.auto_login_days || 0;

  const currentUser = firebaseApp.auth().currentUser;
  const expiration = localStorage.getItem("dreams-expiration");
  if (!currentUser) {
    return request;
  }

  if (+expiration < Date.now()) {
    store.dispatch(setSigningOutProcess(true));
    return firebaseApp
      .auth()
      .signOut()
      .then(() => {
        store.dispatch(setSigningOutProcess(false));
        return request;
      });
  } else if (expiration !== null) {
    return currentUser
      .getIdToken()
      .then((token) => {
        // Update expiration only if auto_login_days is not set
        if (auto_login_days === 0) {
          setExpiration(0);
        }
        request.headers = {
          ...request.headers,
          Authorization: `Bearer ${token}`,
          dreamsdesktop: "dreamsdesktop",
        };
        return request;
      })
      .catch(() => request);
  }
});

class Auth {
  select(state) {
    return state.registration.registrationInProgress;
  }
  constructor() {
    const signOutAndCleanUp = () => {
      this.signOut();
      store.dispatch(setUser(null));
      message.error("המשתמש אינו קיים"); // User does not exist
    };

    firebaseApp.auth().onAuthStateChanged(async (user) => {
      if (store.getState().registration.registrationInProgress) {
        return;
      }

      if (!user) {
        localStorage.removeItem("dreams-expiration");
        store.dispatch(setUser(null));
        return;
      }

      try {
        await this.handleUserSignIn(user);
      } catch (error) {
        console.error("Error handling user sign-in:", error);
        signOutAndCleanUp();
      }
    });
  }

  async handleUserSignIn(user) {
    setExpiration(0);
    if (process.env.REACT_APP_BUILD_TARGET !== "tenants") {
      const action = await store.dispatch(fetchUserProfile());
      if (action.error) throw new Error("UserProfile fetch failed");
      const res = await store.dispatch(loadRecord());
      setExpiration(res.payload?.auto_login_days || 0);
    } else {
      // Use 0 to default to 2 hours
      store.dispatch(
        setUser({
          displayName: user.displayName,
          email: user.email,
        })
      );
    }
  }

  async signInWithProvider(provider, providerTenant) {
    var azureProvider = new firebase.auth.OAuthProvider(provider);
    azureProvider.setCustomParameters({
      tenant: providerTenant,
    });
    let tenant = await getCurrentTenant();
    const auth = firebaseApp.auth();
    auth.tenantId = tenant.name;
    return firebase.auth().signInWithPopup(azureProvider);
  }

  async getToken() {
    const currentUser = firebaseApp.auth().currentUser;
    if (!currentUser) {
      return null;
    }
    return await currentUser.getIdToken();
  }

  async signInWithEmailAndPassword(
    email,
    password,
    isSystemUser,
    ref,
    recaptcha
  ) {
    let tenant = {};
    if (process.env.REACT_APP_BUILD_TARGET === "tenants" || isSystemUser) {
      tenant["name"] =
        configurations[process.env.REACT_APP_CONFIG || "dev"].masterTenant;
    } else {
      tenant = await getCurrentTenant();
    }
    const auth = firebaseApp.auth();
    auth.tenantId = tenant.name;
    var recaptchaVerifier = recaptcha
      ? recaptcha
      : new firebase.auth.RecaptchaVerifier(ref, {
          size: "invisible",
        });

    var resolver;

    try {
      await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      const userInfo = await auth.signInWithEmailAndPassword(email, password);
      if (userInfo) {
        //temporary for users totp enrollments before totp 2factor enforcment
        const hasEnrolledTotpFactor =
          userInfo?.multiFactor?.enrolledFactors.some(
            (factor) => factor.factorId === "totp"
          );
        if (
          !(process.env.REACT_APP_BUILD_TARGET === "tenants" || isSystemUser) &&
          !hasEnrolledTotpFactor
        ) {
          store.dispatch(setEnrolledTotpModalVidible(true));
        }

        return {
          is2FA: false,
          userInfo,
          resolver: null,
          verificationId: null,
          error: null,
        };
      }
    } catch (error) {
      if (error.code === "auth/multi-factor-auth-required") {
        const resolver = getMultiFactorResolver(auth, error);
        return {
          is2FA: true,
          userInfo: null,
          resolver,
          recaptchaVerifier,
          error: null,
        };
      } else {
        return {
          is2FA: false,
          userInfo: null,
          resolver: null,
          verificationId: null,
          error,
        };
        // Handle other errors such as wrong password.
      }
    } finally {
    }
  }

  async generateTotpSecret() {
    const currentUser = firebaseApp.auth().currentUser;
    const multiFactorSession = await multiFactor(currentUser).getSession();
    const totpSecret = await TotpMultiFactorGenerator.generateSecret(
      multiFactorSession
    );
    return { totpSecret, currentUser };
  }

  async enrollTotp(secret, verificationCode) {
    const currentUser = firebaseApp.auth().currentUser;
    const multiFactorAssertion =
      TotpMultiFactorGenerator.assertionForEnrollment(secret, verificationCode);
    try {
      // Enroll to TOTP MFA.
      const data = await multiFactor(currentUser).enroll(
        multiFactorAssertion,
        "totp"
      );
      return {};
    } catch (error) {
      return { error: error.code };
    }
  }

  async unEnrollTotp() {
    const currentUser = firebaseApp.auth().currentUser;
    const multiFactorUser = multiFactor(currentUser);
    const totpFactor = multiFactorUser.enrolledFactors.find(
      (factor) => factor.factorId === TotpMultiFactorGenerator.FACTOR_ID
    );
    try {
      // Unenroll from TOTP MFA.
      const data = await multiFactor(currentUser).unenroll(totpFactor);
      return {};
    } catch (error) {
      return { error: error.code };
    }
  }

  async send2FACode(resolver, recaptchaVerifier) {
    var phoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session,
    };
    var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
    // Send SMS verification code
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier
    );
    return {
      verificationId,
      error: null,
    };
  }

  async confirm2FATotpCode(resolver, factorUid, otp) {
    // Ask the user for the OTP code from the TOTP app.
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
      factorUid,
      otp
    );
    // Finalize the sign-in.
    return resolver.resolveSignIn(multiFactorAssertion);
  }

  async confirm2FAPhoneCode(resolver, verificationId, verificationCode) {
    var cred = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    var multiFactorAssertion =
      firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    // Complete sign-in.
    return resolver.resolveSignIn(multiFactorAssertion);
  }

  async sendPasswordResetEmail(email) {
    let tenant = {};
    if (process.env.REACT_APP_BUILD_TARGET === "tenants") {
      tenant["name"] =
        configurations[process.env.REACT_APP_CONFIG || "dev"].masterTenant;
    } else {
      tenant = await getCurrentTenant();
    }
    firebaseApp.auth().tenantId = tenant.name;
    return firebaseApp.auth().sendPasswordResetEmail(email);
  }

  changePassword(tenantId, actionCode, password) {
    const auth = firebaseApp.auth();
    auth.tenantId = tenantId;
    return auth
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        auth.confirmPasswordReset(actionCode, password);
      })
      .catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
      });
  }

  async linkProvider(
    tenantId,
    authProvider,
    providerTenant,
    email,
    href,
    history
  ) {
    const auth = firebaseApp.auth();
    var provider = new firebase.auth.OAuthProvider(authProvider);
    provider.setCustomParameters({
      tenant: providerTenant,
    });
    auth.tenantId = tenantId;
    await auth.signInWithEmailLink(email, href);
    const result = await auth.currentUser.linkWithPopup(provider);
    var credential = result.credential;
    await auth.signInWithCredential(credential);
  }

  linkAccount(email, password, history) {
    var credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );
    firebaseApp
      .auth()
      .currentUser.linkWithCredential(credential)
      .then(function (usercred) {
        var user = usercred.user;
        console.log("Account linking success", user);
        const url = "/";
        history.push(url);
      })
      .catch(function (error) {
        console.log("Account linking error", error);
      });
  }

  async signOut() {
    store.dispatch(setSigningOutProcess(true));
    try {
      await firebaseApp.auth().signOut();
    } catch (error) {
      console.log("Sign out error", error);
    } finally {
      store.dispatch(setSigningOutProcess(false));
    }
  }

  async signInWithLink(tenantId, email) {
    const auth = firebaseApp.auth();
    auth.tenantId = tenantId;
    return auth.signInWithEmailLink(email);
  }
}

export default new Auth();
