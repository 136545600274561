import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AppLocale, getCurrentLanguage } from "../../languageProvider";
import { getCurrentUser, updateCurrentUser } from "../../api/server";
import Auth from "../../api/auth";
import { withNotifications } from "../notifications/notificationsSlice";
import { unflatten } from "flat";
import * as Sentry from '@sentry/browser';

const initialState = {
  language: "hebrew",
  currentAppLocale: AppLocale["he"],
  profile: null,
  theme: "light",
  loading: true,
  error: null,
  scopePrefix: null,
  signingOutProcess: false,
  changedItems: {
    changed: [],
    values: [],
  },
  twoFAResolved: null,
  is2FA: null,
  enrolledTotpModalVidible: false,
};

export const fetchUserProfile = createAsyncThunk(
  "user/fetchUserProfile",
  getCurrentUser
);

export const commitChanges = createAsyncThunk(
  "user/update",
  withNotifications(
    async (params, thunkAPI) => {
      const { user } = thunkAPI.getState();
      const {
        changedItems: { changed, values },
      } = user;
      const toUpdate = changed.reduce((acc, key) => {
        acc[key] = values[key] === undefined ? null : values[key];
        return acc;
      }, {});
      return updateCurrentUser({ ...unflatten(toUpdate) });
    },
    "update_profile",
    "update_profile_success",
    "update_profile_error"
  )
);
export const enrollTotpFactor = createAsyncThunk(
  "user/enrollTotpFactor",
  withNotifications(
    async (params, thunkAPI) => {
      const { secret, code } = params;
      return Auth.enrollTotp(secret, code);
    },
    "enroll_totp",
    "enroll_totp_success",
    "enroll_totp_error"
  )
);

export const unEnrollTotpFactor = createAsyncThunk(
  "user/unEnrollTotpFactor",
  withNotifications(
    async (params, thunkAPI) => {
      return Auth.unEnrollTotp();
    },
    "unEnroll_totp",
    "unEnroll_totp_success",
    "unEnroll_totp_error"
  )
);

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setLanguage(state, action) {
      const { language } = action.payload;
      const currentAppLocale = AppLocale[getCurrentLanguage(language).locale];
      state.language = language;
      state.currentAppLocale = currentAppLocale;
    },
    setTheme(state, action) {
      state.theme = action.payload;
    },
    setUser(state, action) {
      state.profile = action.payload;
      state.loading = false;
      Sentry.setUser({ username: action?.payload?.data?.user_name })
    },
    setScope(state, action) {
      state.scopePrefix = action.payload;
    },
    setSigningOutProcess(state, action) {
      state.signingOutProcess = action.payload;
    },
    setChangedFormItems(state, action) {
      const { changed, values } = action.payload;
      state.changedItems.changed = changed;
      state.changedItems.values = values;
    },
    setTwoFAResolved(state, action) {
      state.twoFAResolved = action.payload;
    },
    setIs2FA(state, action) {
      state.is2FA = action.payload;
    },
    setEnrolledTotpModalVidible(state, action) {
      state.enrolledTotpModalVidible = action.payload;
    },
  },
  extraReducers: {
    [fetchUserProfile.fulfilled]: (state, action) => {
      state.profile = action.payload.data;
      state.loading = false;
      Sentry.setUser({ username: action?.payload?.data?.user_name })
    },
    [fetchUserProfile.rejected]: (state, action) => {
      state.profile = null;
      state.loading = false;
    },
    [commitChanges.fulfilled]: (state, { payload: { error, data } }) => {
      state.profile = data;
      state.changedItems = { changed: [], values: [] };
      state.error = error;
    },
  },
});

export const {
  setLanguage,
  setUser,
  setTheme,
  setScope,
  setSigningOutProcess,
  setChangedFormItems,
  setTwoFAResolved,
  setIs2FA,
  setEnrolledTotpModalVidible,
} = userSlice.actions;

export default userSlice.reducer;
